import React from "react"
import { graphql } from "gatsby"
import Helmet from "react-helmet"
// import Layout from "../components/layout"
import SEO from "../helpers/seo"

export default function Careers({ data }) {
  const {
    headline,
    headlineTitle,
    headlineDescription,
    seoTitle,
    seoKeywords,
    sectionTitle,
    job,
  } = data.contentfulCareerPage

  const createMarkup = content => {
    return { __html: content }
  }

  return (
    <React.Fragment>
      <Helmet>
        <body className="careers-page" />
      </Helmet>
      <SEO title={seoTitle} keywords={seoKeywords} />
      <div className="content-wrapper">
        <section className="intro">
          <div
            className="intro-title"
            dangerouslySetInnerHTML={createMarkup(
              headline.content[0].content[0].value
            )}
          />
          <div className="service">
            <div className="title">{headlineTitle}</div>
            <p
              dangerouslySetInnerHTML={createMarkup(
                headlineDescription.headlineDescription
              )}
            />
          </div>
        </section>
        <section className="careers">
          <div className="title">{sectionTitle}</div>
          <div className="careers-listing">
            {job.map(post => (
              <div className="careers-wrapper" key={post.id}>
                <div className="left-side">
                  <div className="job-title">{post.jobTitle}</div>
                  <div className="service">
                    <div className="title">LOCATION</div>
                    <p>{post.jobLocation}</p>
                  </div>
                  <div className="service">
                    <div className="title">POSTED</div>
                    <p>
                      {post.jobPosted}
                      {parseInt(post.dateDifference) > 1
                        ? ` (${post.dateDifference} days ago)`
                        : null}
                      {parseInt(post.dateDifference) === 1 &&
                      parseInt(post.dateDifference) !== 0
                        ? ` (${post.dateDifference} day ago)`
                        : null}
                    </p>
                  </div>
                  <div className="service">
                    <div className="title">DETAILED DESCRIPTION</div>
                    <a
                      href={post.jobDetails.file.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View <span>→</span>
                    </a>
                  </div>
                </div>
                <div className="right-side">
                  <div className="service">
                    <div className="title">SUMMARY</div>
                    <p>{post.jobSummary.content[0].content[0].value}</p>
                  </div>
                  <div className="job-description">
                    <div className="service">
                      <div className="title">CONTACT</div>
                      <p>
                        <strong>{post.contactPersonName}</strong>,{" "}
                        {post.contactPersonPosition}
                        <br />
                        Tel.{" "}
                        <a href={`tel:${post.contactPersonPhone}`}>
                          {post.contactPersonPhone}
                        </a>
                        <br />
                        <a
                          href={`mailto:${post.contactPersonEmail}`}
                          className="hover-underline"
                        >
                          {post.contactPersonEmail}
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>
      </div>
    </React.Fragment>
  )
}

export const query = graphql`
  query JobPostQuery {
    contentfulCareerPage {
      headline {
        content {
          content {
            value
          }
        }
      }
      headlineTitle
      headlineDescription {
        headlineDescription
      }
      job {
        contactPersonEmail
        contactPersonName
        contactPersonPhone
        contactPersonPosition
        jobTitle
        jobLocation
        jobSummary {
          content {
            content {
              value
            }
          }
        }
        jobPosted(formatString: "MMMM DD, YYYY")
        dateDifference(difference: "days")
        jobDetails {
          file {
            url
          }
        }
        id
      }
      sectionTitle
      seoTitle
      seoKeywords
    }
  }
`
